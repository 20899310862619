@import "../variables";

.blog-post-page {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1rem;

    .loading,
    .error {
        text-align: center;
        padding: 2rem;
        font-size: 1.2rem;
    }

    .error {
        color: $danger;
    }

    article {
        header {
            margin-bottom: 2rem;

            h1 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
                color: $primary;
            }

            .meta {
                color: $gray-600;
                font-size: 0.9rem;

                time {
                    margin-right: 1rem;
                }

                .category {
                    &:before {
                        content: '•';
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .content {
            font-size: 1.1rem;
            line-height: 1.6;
            margin-bottom: 2rem;

            p {
                margin-bottom: 1.5rem;
            }

            img {
                max-width: 100%;
                height: auto;
                margin: 1rem 0;
            }
        }

        .tags {
            border-top: 1px solid $gray-300;
            padding-top: 1rem;

            .tag {
                display: inline-block;
                background: $gray-100;
                padding: 0.3rem 0.8rem;
                border-radius: 20px;
                margin: 0.3rem;
                font-size: 0.9rem;
                color: $gray-700;

                &:hover {
                    background: $gray-200;
                }
            }
        }
    }
} 