.icon-1 {
    width: 0.8rem !important;
    height: 0.8rem !important;
}

.icon-2 {
    width: 1rem !important;
    height: 1rem !important;
}

.icon-3 {
    width: 1.25rem !important;
    height: 1.25rem !important;
}

.icon-4 {
    width: 1.50rem !important;
    height: 1.50rem !important;
}

.icon-5 {
    width: 1.75rem !important;
    height: 1.75rem !important;
}

.icon-6 {
    width: 2rem !important;
    height: 2rem !important;
}

.icon-7 {
    width: 2.25rem !important;
    height: 2.25rem !important;
}

.icon-8 {
    width: 2.50rem !important;
    height: 2.50rem !important;
}

.icon-9 {
    width: 2.75rem !important;
    height: 2.75rem !important;
}

.icon-10 {
    width: 3rem !important;
    height: 3rem !important;
}

.icon-11 {
    width: 3.25rem !important;
    height: 3.25rem !important;
}

.icon-12 {
    width: 3.50rem !important;
    height: 3.50rem !important;
}

.icon-13 {
    width: 3.75rem !important;
    height: 3.75rem !important;
}

.icon-14 {
    width: 4rem !important;
    height: 4rem !important;
}

.icon-15 {
    width: 4.25rem !important;
    height: 4.25rem !important;
}

.icon-16 {
    width: 4.50rem !important;
    height: 4.50rem !important;
}

.icon-17 {
    width: 4.75rem !important;
    height: 4.75rem !important;
}

.icon-18 {
    width: 5rem !important;
    height: 5rem !important;
}

.icon-19 {
    width: 5.25rem !important;
    height: 5.25rem !important;
}

.icon-20 {
    width: 5.50rem !important;
    height: 5.50rem !important;
}