// https://sass-lang.com/documentation/at-rules/control/each
// generate font-weight utilities like: .font-weight-100, font-weight-md-100 etc.
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $font-weight in $font-weights-custom {
            .font-weight#{$infix}-#{$font-weight} {
                font-weight: $font-weight;
            }
        }
    }
}

// generate font-size utilities like: .font-size-1, font-size-md-1 etc.
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $size,$sizeInRem in $font-sizes-custom {
            .font-size#{$infix}-#{$size} {
                font-size: $sizeInRem;
            }
        }
    }
}