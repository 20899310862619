/* Bootstrap 5 */

// Create size utilities from 1% to 100%. Eg: .w-15, h-50, w-13, h-lg-25, etc.
// Define sizes map for widths and heights
$sizes: ();
@for $i from 1 through 100 {
  $sizes: map-merge($sizes, ($i * 1: #{$i * 1} + "%" ));
}

// Generate width and height utilities with breakpoints
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $value in $sizes {
      // Width utilities like: .w-lg-15, .w-md-10, etc.
      .w#{$infix}-#{$size} {
        width: #{$value} !important;
      }

      // Height utilities like: .h-lg-15, .h-md-10, etc.
      .h#{$infix}-#{$size} {
        height: #{$value} !important
      }
    }
  }
}