/* Colors: social networks **/
$facebook: #1877f2;
$google: #dd4b39;
$twitter: #0f1419;
$instagram: #e1306c;
$github: #333;
$apple: #333333;
$pinterest: #bd081c;
$whatsapp: #25d366;
$linkedin: #0077b5;

/* Init a map for grays **/
$grays-with-name: ();

/* Init a map for other colors **/
$other-colors: ();

/* Font weights **/
$font-weights-custom: 100, 200, 300, 400, 500, 600, 700, 800, 900;

/* Font sizes **/
$font-sizes-custom: (
    r25: $font-size-base * 0.25,
    r30: $font-size-base * 0.30,
    r35: $font-size-base * 0.35,
    r40: $font-size-base * 0.40,
    r45: $font-size-base * 0.45, 
    r50: $font-size-base * 0.50,
    r55: $font-size-base * 0.55,
    r60: $font-size-base * 0.60,
    r65: $font-size-base * 0.65,
    r70: $font-size-base * 0.70, 
    r75: $font-size-base * 0.75,
    r80: $font-size-base * 0.80,
    r85: $font-size-base * 0.85, 
    r90: $font-size-base * 0.90,
    r95: $font-size-base * 0.95,
    1: $font-size-base,
    r105: $font-size-base * 1.05,
    r110: $font-size-base * 1.10,
    r115: $font-size-base * 1.15,
    2: $font-size-base * 1.25,
    3: $font-size-base * 1.50,
    4: $font-size-base * 1.75,
    5: $font-size-base * 2.00,
    6: $font-size-base * 2.25,
    7: $font-size-base * 2.50,
    8: $font-size-base * 2.75,
    9: $font-size-base * 3.00,
    10: $font-size-base * 3.25,
    11: $font-size-base * 3.50,
    12: $font-size-base * 4.00,
    13: $font-size-base * 4.50,
    14: $font-size-base * 5.00,
    
    h1: $font-size-base * 2.5,
    h2: $font-size-base * 2,
    h3: $font-size-base * 1.75,
    h4: $font-size-base * 1.5,
    h5: $font-size-base * 1.25,
    h6: $font-size-base * 1,
    small-1: $font-size-base * 0.875,
    small-2: $font-size-base * 0.75,
    small-3: $font-size-base * 0.625,
);