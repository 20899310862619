// Font
$font-family-sans-serif: system-ui, -apple-system, "Open Sans", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: 'Microsoft PhagsPa', $font-family-sans-serif;
$font-size-base: 0.875rem;

// Import variables from xFrame4
@import "../../xFrame4/scss/variables";

// Colors: main
$primary: #4c4d4e;
$secondary: #95a5a6;
$info: #dd2024;
$body-color: #4c4d4e;

// Colors: other

$other-colors: map-merge($other-colors, (
    
));

// Colors: grays

$grays-with-name: map-merge($grays-with-name, (
));

// Colors: misc
$min-contrast-ratio: 2.5;


// Buttons
$btn-border-radius: 0;